import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import leadsSlice from "../features/wallet/leadSlice";
import { currentUserApi } from "../features/common/userSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { loggedInBrandApi } from "../features/common/loggedInBrandSlice";

const store = configureStore({
  reducer: {
    header: headerSlice,
    rightDrawer: rightDrawerSlice,
    modal: modalSlice,
    lead: leadsSlice,
    [currentUserApi.reducerPath]: currentUserApi.reducer,
    [loggedInBrandApi.reducerPath]: loggedInBrandApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(currentUserApi.middleware)
      .concat(loggedInBrandApi.middleware),
});

setupListeners(store.dispatch);

export default store;
