import Lottie from "lottie-react";
import DazzifyAni from "../../src/components/Input/DazzifyAni.json"; // Correct the import path

function SuspenseContent() {
  // return (
  //   <div className="flex justify-center items-center h-screen">
  //     <Lottie
  //       animationData={DazzifyAni}
  //       style={{ width: "50%", height: "50%" }}
  //     />
  //   </div>
  // );
}

export default SuspenseContent;
