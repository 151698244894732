import React from "react";
import { withTranslation } from "react-i18next";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorCode: null };
  }

  static getDerivedStateFromError(error) {
    if (error.response && error.response.status === 500) {
      return { hasError: true, errorCode: 500 };
    }
    return { hasError: true };
  }

  render() {
    const { t } = this.props; // Get the t function from props

    if (this.state.hasError) {
      return (
        <div className="flex flex-col justify-center items-center h-screen bg-red-100 text-red-800 text-center p-4">
          {this.state.errorCode === 500 ? (
            <>
              <svg
                width="580"
                height="515"
                viewBox="0 0 580 515"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  opacity="0.1"
                  cx="261.637"
                  cy="273.863"
                  r="200"
                  fill="#B47FF0"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M276.277 258.654C284.967 256.189 294.172 255.353 303.889 256.144C303.889 251.124 303.889 246.104 303.889 241.083C308.867 240.222 312.215 241.897 313.93 246.104C310.606 242.715 308.096 243.134 306.399 247.359C308.397 250.1 310.071 253.029 311.419 256.144C308.096 257.414 306.422 260.76 306.399 266.185C305.563 266.185 304.725 266.185 303.889 266.185C303.889 263.675 303.889 261.164 303.889 258.654C294.684 258.654 285.482 258.654 276.277 258.654Z"
                  fill="#4B108D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M55.3805 271.203C77.1359 271.203 98.8889 271.203 120.644 271.203C120.218 267.885 121.053 264.955 123.154 262.418C128.953 261.17 134.809 260.753 140.725 261.163C140.281 254.935 142.791 250.334 148.256 247.357C161.617 246.104 175.004 245.685 188.418 246.102C180.24 247.786 171.874 249.877 163.317 252.377C170.36 263.065 167.014 268.921 153.276 269.948C155.827 271.251 157.498 273.344 158.297 276.223C154.295 284.971 152.624 294.176 153.276 303.835C164.991 303.835 176.703 303.835 188.418 303.835C188.002 286.244 188.418 268.673 189.673 251.122C190.928 271.186 191.345 291.267 190.928 311.365C177.419 307.053 163.196 304.962 148.256 305.09C144.009 301.573 142.334 296.972 143.236 291.284C136.491 291.696 129.796 291.277 123.154 290.029C121.053 287.491 120.218 284.562 120.644 281.244C91.3584 281.244 62.075 281.244 32.7891 281.244C17.5526 278.987 7.09277 270.621 1.41231 256.142C0.157237 170.803 -0.261957 85.4579 0.157237 0.108154C3.47565 -0.318573 6.40499 0.517303 8.94275 2.61829C9.77863 87.1271 10.617 171.633 11.4529 256.142C14.5931 263.374 20.0301 267.975 27.7689 269.948C36.9359 271.201 46.1381 271.617 55.3805 271.203Z"
                  fill="#4B108D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M188.42 246.102C189.226 242.216 191.736 240.542 195.951 241.081C197.555 265.498 195.88 288.925 190.93 311.365C191.347 291.267 190.93 271.186 189.675 251.122C188.42 268.673 188.003 286.244 188.42 303.835C176.705 303.835 164.993 303.835 153.278 303.835C152.625 294.176 154.297 284.971 158.298 276.223C157.5 273.344 155.828 271.251 153.278 269.948C167.016 268.921 170.362 263.065 163.319 252.377C171.876 249.877 180.242 247.786 188.42 246.102Z"
                  fill="#4B108D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M180.895 333.959C183.44 330.784 187.205 329.112 192.19 328.939C195.955 329.775 199.721 330.613 203.486 331.449C196.136 333.919 188.606 334.755 180.895 333.959Z"
                  fill="#4B108D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M193.445 364.082C195.89 356.18 200.91 351.579 208.506 350.276C205.346 357.121 200.326 361.725 193.445 364.082Z"
                  fill="#4B108D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M221.055 376.632C221.055 369.102 221.055 361.571 221.055 354.041C223.565 354.041 226.075 354.041 228.585 354.041C228.585 361.571 228.585 369.102 228.585 376.632C226.075 376.632 223.565 376.632 221.055 376.632Z"
                  fill="#4B108D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M286.314 198.411C285.519 190.7 286.355 183.17 288.825 175.82C289.66 179.585 290.499 183.35 291.335 187.115C291.162 192.101 289.49 195.866 286.314 198.411Z"
                  fill="#4B108D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M276.277 286.263C286.318 286.263 296.359 286.263 306.399 286.263C307.072 290.277 306.233 293.623 303.889 296.303C294.684 296.303 285.482 296.303 276.277 296.303C276.277 292.957 276.277 289.609 276.277 286.263Z"
                  fill="#B47FF0"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M276.277 254.092C286.318 254.092 296.359 254.092 306.399 254.092C307.072 258.105 306.233 261.451 303.889 264.132C294.684 264.132 285.482 264.132 276.277 264.132C276.277 260.786 276.277 257.438 276.277 254.092Z"
                  fill="#B47FF0"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M303.891 205.942C306.87 198.745 311.89 192.889 318.952 188.371C321.514 189.631 321.934 191.303 320.207 193.391C315.297 198.532 309.857 202.714 303.891 205.942Z"
                  fill="#4B108D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M308.91 223.517C311.455 220.341 315.221 218.67 320.206 218.496C323.971 219.332 327.736 220.171 331.501 221.007C324.152 223.477 316.621 224.312 308.91 223.517Z"
                  fill="#4B108D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M303.891 296.305C306.235 293.624 307.073 290.278 306.401 286.264C306.401 279.569 306.401 272.877 306.401 266.183C306.423 260.758 308.098 257.412 311.421 256.142C310.073 253.027 308.399 250.098 306.401 247.357C308.098 243.132 310.608 242.713 313.931 246.102C320.972 248.158 328.503 249.413 336.523 249.867C343.634 255.306 350.748 260.743 357.859 266.183C352.838 277.975 353.258 289.688 359.114 301.325C358.835 302.874 357.999 304.129 356.604 305.09C338.721 305.497 321.15 307.588 303.891 311.365C303.891 306.345 303.891 301.325 303.891 296.305Z"
                  fill="#4B108D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M313.926 246.104C327.34 245.688 340.727 246.107 354.088 247.359C358.242 250.984 359.917 255.588 359.108 261.165C365.025 260.756 370.881 261.173 376.679 262.42C380 264.469 381.672 267.398 381.7 271.206C381.7 274.552 381.7 277.9 381.7 281.246C382.051 285.323 380.377 288.25 376.679 290.032C370.881 291.279 365.025 291.696 359.108 291.287C359.108 294.633 359.108 297.981 359.108 301.327C353.252 289.69 352.833 277.978 357.853 266.185C350.742 260.746 343.628 255.309 336.517 249.87C328.497 249.415 320.967 248.16 313.926 246.104Z"
                  fill="#4B108D"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M381.699 281.248C381.699 277.902 381.699 274.553 381.699 271.207C398.434 271.207 415.167 271.207 431.902 271.207C453.128 269.47 468.606 277.837 478.34 296.309C479.176 359.898 480.014 423.49 480.85 487.079C485.381 496.224 492.492 502.08 502.186 504.65C528.643 505.509 554.583 508.019 580.001 512.181C551.99 515.09 523.543 515.509 494.656 513.436C482.356 508.661 474.406 499.876 470.809 487.079C469.974 423.49 469.135 359.898 468.299 296.309C465.651 289.893 461.05 285.708 454.493 283.758C430.682 281.263 406.419 280.427 381.699 281.248Z"
                  fill="#4B108D"
                />
              </svg>
              <h1 className="text-3xl font-bold mb-4">{t("serverError")}</h1>
              <p className="text-lg">{t("serverErrorMessage")}</p>
            </>
          ) : (
            <>
              <img src="/no-authorization.svg" className="w-32 sm:w-48" />
              <h1 className="text-3xl font-bold mb-4">{t("genericError")}</h1>
              <p className="text-lg">{t("genericErrorMessage")}</p>
            </>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

// Wrap the ErrorBoundary component with the withTranslation HOC
export default withTranslation()(ErrorBoundary);
