import React, { useEffect, lazy, Suspense } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";
import { Toaster } from "react-hot-toast";
import MediaContextProvider from "./Context/Media";
import { Spinner } from "@nextui-org/spinner";
import "react-image-crop/dist/ReactCrop.css";
import Lottie from "lottie-react";
import DazzifyAni from "../src/components/Input/DazzifyAni.json";

initializeApp();

const token = checkAuth();

// Lazy loading for pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Security = lazy(() => import("./features/settings/component/security"));
const index = lazy(() => import("./features/settings/index"));
const Help = lazy(() => import("./features/settings/component/help"));
const Ticket = lazy(() => import("./features/settings/component/ticket"));
const Walletset = lazy(() => import("./features/settings/component/walletset"));
const Settings = lazy(() => import("./features/settings"));
const Categories = lazy(() => import("./features/categories/categories"));
const Package = lazy(() => import("./features/categories/component/packages"));
const Service = lazy(() => import("./features/categories/component/services"));
const Create = lazy(() =>
  import("./features/categories/component/create/create")
);
const ServicesDet = lazy(() =>
  import("./features/categories/component/servicesDet")
);

function App() {
  useEffect(() => {
    themeChange(false);
  }, []);

  useEffect(() => {
    const language = localStorage.getItem("language");
    const direction = language === "ar" ? "rtl" : "ltr";
    document.documentElement.setAttribute("dir", direction);
  }, []);

  return (
    <Router>
      <Suspense
        fallback={
          <div className="w-full h-screen flex justify-center items-center">
            <Spinner
              color="#4b108d"
              style={{ width: "180px", height: "180px" }}
            />
          </div>
        }
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/security"
            element={
              <Layout>
                <Security />
              </Layout>
            }
          />
          <Route
            path="/help"
            element={
              <Layout>
                <Help />
              </Layout>
            }
          />
          <Route
            path="/servicesDet/:id"
            element={
              <Layout>
                <ServicesDet />
              </Layout>
            }
          />
          <Route
            path="/index"
            element={
              <Layout>
                <Settings />
              </Layout>
            }
          />
          <Route
            path="/walletset"
            element={
              <Layout>
                <Walletset />
              </Layout>
            }
          />
          <Route
            path="/categories"
            element={
              <Layout>
                <Categories />
              </Layout>
            }
          />
          <Route
            path="/packages"
            element={
              <Layout>
                <Package />
              </Layout>
            }
          />
          <Route
            path="/services"
            element={
              <Layout>
                <Service />
              </Layout>
            }
          />
          <Route
            path="/create"
            element={
              <Layout>
                <Create />
              </Layout>
            }
          />
          <Route
            path="/ticket"
            element={
              <Layout>
                <Ticket />
              </Layout>
            }
          />

          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route
            path="*"
            element={<Navigate to={token ? "/login" : "/login"} replace />}
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
