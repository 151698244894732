import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { baseUrl } from "../../api/baseUrl";

const API_BASE_URL = `${baseUrl}/api/v1/provider/`;

export const loggedInBrandApi = createApi({
  reducerPath: "loggedInBrandApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers) => {
      const token = Cookies.get("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLoggedInBrand: builder.query({
      query: () => "brand",
    }),
  }),
});

export const { useGetLoggedInBrandQuery } = loggedInBrandApi;
